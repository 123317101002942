<template>
  <div>
    <banner></banner>
    <div class="content_area">
      <div class="title">{{$t('quotationUnit')}}</div>
      <div class="table">
        <el-table
            :data="dataList"
            style="width: 100%"
            border
            :cell-class-name="cellClassNameFn"
            :span-method="tableSpanMethod"
        >
          <el-table-column label="" :width="LOCALE === 'zh'? 180 : 240" align="center">
            <template slot-scope="scope">{{ scope.row.way }}</template>
          </el-table-column>
          <el-table-column prop="name" label="" :width="LOCALE === 'zh'? 180 : 280" align="center">
            <template slot-scope="scope">
              <div class="companyBox english">
                <div class="company english">{{ scope.row.company }}</div>
                <div class="companyQuestion point english" v-if="scope.row.question" @click="handleQuestion(scope.row)">{{$t('Detail')}}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" :label="$t('marketQuantity')" :width="LOCALE === 'zh'? 200 : 220" align="center">
            <template slot-scope="scope">
              <span class="amount" :class="LOCALE === 'en' ? 'english' : ''">{{ scope.row.amount }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="equity" v-if="LOCALE === 'zh'" :label="$t('sponsorChanceMoneyRMB')" align="center">
            <template slot-scope="scope">
              <div class="moneyList">
                <div class="moneyItem" v-for="(item, index) in scope.row.RMBList" :key="index">
                  <div v-if="item.RMB">
                    <span class="unitIcon">¥</span>
                    <span class="moneyNumber">{{ item.RMB }} </span>
                    <span class="RMB">RMB </span>
                    <span v-if="item.unit" class="unit">{{ item.unit }}</span>
                  </div>
                  <div v-if="item.text">
                    <span :class="scope.row.id === 2 ? 'textActive' : 'text'">{{ item.text }}</span>
                  </div>
                  <div v-if="item.end" class="unitIcon">{{ item.end }}</div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="equity" :label="$t('USD')" align="center">
            <template slot-scope="scope">
              <div class="moneyList">
                <div class="moneyItem" v-for="(item, index) in scope.row.USDList" :key="index">
                  <div v-if="item.USD">
                    <span class="unitIcon">$</span>
                    <span class="moneyNumber">{{ item.USD }} </span>
                    <span class="RMB">USD </span>
                  </div>
                  <div v-if="item.text">
                    <span class="text">{{ item.text }}</span>
                  </div>
                  <div v-if="item.end" class="unitIcon">{{ item.end }}</div>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-dialog
        :title="detailRow.company"
        :visible.sync="dialogVisible"
        v-if="dialogVisible"
        append-to-body
        top="10px"
        :close-on-click-modal="false"
        width="1300px"
        :destroy-on-close="true"
        :before-close="beforeClose"
        center=""
    >
      <div class="rightsTitle">{{$t('sponsorBenefit')}}</div>
      <div class="rightsBox">
          <div v-if="detailRow.rights.onLine.length" class="color20">{{$t('onLine')}}：</div>
          <div class="right english" v-for="(it, index) in detailRow.rights.onLine" :key="index">● {{ it }}</div>
          <div v-if="detailRow.rights.offLine.length" class="color20">{{$t('offLine')}}：</div>
          <div class="right english" v-for="(it, index) in detailRow.rights.offLine" >● {{ it }}</div>
          <div class="right english" v-for="(it, index) in detailRow.rights.defalut">● {{ it }}</div>
      </div>
      <div class="momeyRMBTitle english">{{$t('productPrice')}}: {{ detailRow.momeyRMB }}</div>
      <div class="momeyRMBTitle english">{{$t('marketQuantity')}}: {{ detailRow.amount }}</div>
      <div v-for="(img, index) in detailRow.imgs" class="imgs" :key="index">
        <img :src="img" alt="" class="img">
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import banner from '~exp/components/common/banner'
  export default {
    name: "index",
    components: { banner },
    data() {
      return {
        dataList: [
          {
            id: 1,
            way: this.$t('offlineSponsor'),
            company: this.$t('expoExclusiveNaming'),
            amount: this.$t('exclusive'),
            RMBList: [
              {
                RMB: '100000',
                text: ''
              }
            ] ,
            USDList: [
              {
                USD: '15000',
                text: ''
              }
            ],
            question: true,
            rights: {
              onLine: [
                this.$t('sponsorTableText1_1'),
                this.$t('sponsorTableText1_2'),
                this.$t('sponsorTableText1_3'),
                this.$t('sponsorTableText1_4'),
                this.$t('sponsorTableText1_5'),
                this.$t('sponsorTableText1_6'),
              ],
              offLine: [
                this.$t('sponsorTableText1_7'),
                this.$t('sponsorTableText1_8'),
                this.$t('sponsorTableText1_9'),
                this.$t('sponsorTableText1_10'),
                this.$t('sponsorTableText1_11'),
                this.$t('sponsorTableText1_12'),
                this.$t('sponsorTableText1_13'),
                this.$t('sponsorTableText1_14'),
              ],
              defalut: [],
            },
            momeyRMB: '100000RMB,$15000',
            imgs: [
             'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/sponsorChance/1.png'
            ]
          },
          {
            id: 2,
            company: this.$t('overseasExhibitionNaming'),
            amount: this.$t('exclusive'),
            RMBList: [
              {
                RMB: '',
                text: this.$t('sponsorTableText2_1'),
              }
            ] ,
            USDList: [
              {
                USD: '7700 ',
                text: ''
              }
            ],
            question: true,
            rights: {
              defalut: [
                this.$t('sponsorTableText2_2'),
                this.$t('sponsorTableText2_3'),
                this.$t('sponsorTableText2_4'),
                this.$t('sponsorTableText2_5'),
                this.$t('sponsorTableText2_6'),
                this.$t('sponsorTableText2_7'),
              ],
              onLine: [],
              offLine: []
            },
            momeyRMB: '$7700',
            imgs: [
              'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/sponsorChance/2.png'
            ]
          },
          {
            id: 3,
            company: this.$t('dinnerNaming'),
            amount: this.$t('exclusive'),
            RMBList: [
              {
                RMB: '35000 ',
                text: ''
              }
            ] ,
            USDList: [
              {
                USD: '5200  ',
                text: ''
              }
            ],
            question: true,
            rights: {
              defalut: [
                this.$t('sponsorTableText3_1'),
                this.$t('sponsorTableText3_2'),
                this.$t('sponsorTableText3_3'),
                this.$t('sponsorTableText3_4'),
                this.$t('sponsorTableText3_5'),
                this.$t('sponsorTableText3_6'),
                this.$t('sponsorTableText3_7'),
                this.$t('sponsorTableText3_8'),
              ],
              onLine: [],
              offLine: []
            },
            momeyRMB: '35000RMB,$5200',
            imgs: [
              'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/sponsorChance/3.png'
            ]
          },
          {
            id: 4,
            company: this.$t('ceilingAdvertising'),
            amount: this.$t('onlyTwoCompanies'),
            RMBList: [
              {
                RMB: '30000',
                text: this.$t('sponsorTableText4_1'),
              }
            ] ,
            USDList: [
              {
                USD: '4500',
                text: this.$t('sponsorTableText4_2'),
              }
            ],
            question: true,
            rights: {
              defalut: [
                this.$t('sponsorTableText4_3'),
                this.$t('sponsorTableText4_4'),
                this.$t('sponsorTableText4_5'),
                this.$t('sponsorTableText4_6'),
              ],
              onLine: [],
              offLine: []
            },
            momeyRMB: this.$t('sponsorTableText4_7'),
            imgs: [
              'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/sponsorChance/4.png'
            ]
          },
          {
            id: 5,
            company: this.$t('superBanner'),
            amount: this.$t('onlyTwoCompanies'),
            RMBList: [
              {
                RMB: '30000',
                text: this.$t('sponsorTableText5_1'),
              }
            ] ,
            USDList: [
              {
                USD: '4500',
                text: ''
              }
            ],
            question: true,
            rights: {
              defalut: [
                this.$t('sponsorTableText5_2'),
                this.$t('sponsorTableText5_3'),
                this.$t('sponsorTableText5_4'),
              ],
              onLine: [],
              offLine: []
            },
            momeyRMB:  this.$t('sponsorTableText5_5'),
            imgs: [
              'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/sponsorChance/5.1.png',
              'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/sponsorChance/5.2.png',
            ]
          },
          {
            id: 6,
            company: this.$t('notebookSuit'),
            amount: this.$t('exclusive'),
            RMBList: [
              {
                RMB: '30000',
                text: this.$t('sponsorTableText6_1'),
              }
            ] ,
            USDList: [
              {
                USD: '4500',
                text: ''
              }
            ],
            question: true,
            rights: {
              defalut: [
                this.$t('sponsorTableText6_2'),
                this.$t('sponsorTableText6_3'),
                this.$t('sponsorTableText6_4'),
              ],
              onLine: [],
              offLine: []
            },
            momeyRMB: '30000RMB, $4500',
            imgs: [
              'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/sponsorChance/7.png',
            ]
          },
          {
            id: 7,
            company:  this.$t('businessBackpack'),
            amount: this.$t('exclusive'),
            RMBList: [
              {
                RMB: '50000',
                text: ''
              }
            ] ,
            USDList: [
              {
                USD: '7700',
                text: ''
              }
            ],
            question: true,
            rights: {
              defalut: [
                this.$t('sponsorTableText7_1'),
                this.$t('sponsorTableText7_2'),
                this.$t('sponsorTableText7_3'),
                this.$t('sponsorTableText7_4'),
              ],
              onLine: [],
              offLine: []
            },
            momeyRMB: '50000RMB, $7700',
            imgs: [
             'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/sponsorChance/6.png',
            ]
          },
          {
            id: 8,
            company:this.$t('StreetFlagAdvertising'),
            amount: this.$t('exclusive'),
            RMBList: [
              {
                RMB: '20000',
                text: ''
              }
            ] ,
            USDList: [
              {
                USD: '3200',
                text: ''
              }
            ],
            rights: {
              defalut: [
                this.$t('sponsorTableText8_1'),
                this.$t('sponsorTableText8_2'),
                this.$t('sponsorTableText8_3'),
              ],
              onLine: [],
              offLine: []
            },
            momeyRMB: '20000RMB, $3200',
            imgs: [
              'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/sponsorChance/8.1.jpg',
              'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/sponsorChance/8.2.png',
            ]
          },
          {
            id: 9,
            company: this.$t('sponsorTableText9_1'),
            amount: this.$t('exclusive'),
            RMBList: [
              {
                RMB: '6000',
                text: this.$t('sponsorTableText4_1'),
              }
            ] ,
            USDList: [
              {
                USD: '900',
                text: this.$t('sponsorTableText4_2'),
              }
            ],
            question: true,
            rights: {
              defalut: [
                this.$t('sponsorTableText9_2'),
                this.$t('sponsorTableText9_3'),
                this.$t('sponsorTableText9_4'),
                this.$t('sponsorTableText9_5'),
              ],
              onLine: [],
              offLine: []
            },
            momeyRMB: this.$t('sponsorTableText9_6'),
            imgs: [
              'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/sponsorChance/9.png',
            ]
          },
          {
            id: 10,
            company:  this.$t('chestCard'),
            amount: this.$t('exclusive'),
            RMBList: [
              {
                RMB: '10000',
                text: this.$t('sponsorTableText6_1'),
              }
            ] ,
            USDList: [
              {
                USD: '1600',
                text: ''
              }
            ],
            question: true,
            rights: {
              defalut: [
                this.$t('sponsorTableText10_1'),
                this.$t('sponsorTableText10_2'),
                this.$t('sponsorTableText10_3'),
                this.$t('sponsorTableText10_4'),
              ],
              onLine: [],
              offLine: []
            },
            momeyRMB:  this.$t('sponsorTableText10_5'),
            imgs: [
              'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/sponsorChance/10.png',
            ]
          },
          {
            id: 11,
            company:  this.$t('liftingRope'),
            amount: this.$t('exclusive'),
            RMBList: [
              {
                RMB: '10000',
                text: this.$t('sponsorTableText6_1'),
              }
            ] ,
            USDList: [
              {
                USD: '1600',
                text: ''
              }
            ],
            question: true,
            rights: {
              defalut: [
                this.$t('sponsorTableText11_1'),
                this.$t('sponsorTableText11_2'),
                this.$t('sponsorTableText11_3'),
              ],
              onLine: [],
              offLine: []
            },
            momeyRMB:  this.$t('sponsorTableText11_4'),
            imgs: [
              'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/sponsorChance/11.1.jpg',
              'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/sponsorChance/11.2.jpg',
            ]
          },
          {
            id: 12,
            company: this.$t('meetingEatTicket'),
            amount: this.$t('exclusive'),
            RMBList: [
              {
                RMB: '10000',
                text: this.$t('sponsorTableText6_1'),
              }
            ] ,
            USDList: [
              {
                USD: '1600',
                text: ''
              }
            ],
            question: true,
            rights: {
              defalut: [
                this.$t('sponsorTableText12_1'),
                this.$t('sponsorTableText12_2'),
                this.$t('sponsorTableText12_3'),
              ],
              onLine: [],
              offLine: []
            },
            momeyRMB: '10000RMB, $1600',
            imgs: [
              'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/sponsorChance/12.1.png',
              'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/sponsorChance/12.2.png',
            ]
          },
          {
            id: 13,
            company: this.$t('souvenir'),
            amount: this.$t('exclusive'),
            RMBList: [
              {
                RMB: '',
                text: this.$t('discuss'),
              }
            ] ,
            USDList: [
              {
                USD: '',
                text:  this.$t('discuss'),
              }
            ],
            question: true,
            rights: {
              defalut: [
                this.$t('sponsorTableText13_1'),
              ],
              onLine: [],
              offLine: []
            },
            momeyRMB:  this.$t('discuss'),
            imgs: [
              'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/sponsorChance/13.1.png',
              'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/sponsorChance/13.2.jpg',
            ]
          },
          {
            id: 14,
            company: this.$t('sponsorTableText14_1'),
            amount: this.$t('onlySixCompanies'),
            RMBList: [
              {
                RMB: '10000',
                unit: this.$t('sponsorTableText14_2'),
                text: this.$t('sponsorTableText14_3'),
              },
              {
                RMB: '8000',
                unit: this.$t('sponsorTableText14_2'),
                text: this.$t('sponsorTableText14_4'),
              }
            ] ,
            USDList: [
              {
                USD: '1600',
              },
              {
                USD: '1400',
              }
            ],
            question: true,
            rights: {
              defalut: [
                this.$t('sponsorTableText14_5'),
                this.$t('sponsorTableText14_6'),
                this.$t('sponsorTableText14_7'),
                this.$t('sponsorTableText14_8'),
              ],
              onLine: [],
              offLine: []
            },
            momeyRMB:  this.$t('sponsorTableText14_9'),
            imgs: [
              'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/sponsorChance/14.1.png',
              'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/sponsorChance/14.2.jpg',
            ]
          },
          {
            id: 15,
            company: this.$t('sponsorTableText15_1'),
            amount: this.$t('exclusive'),
            RMBList: [
              {
                RMB: '10000',
                text: this.$t('sponsorTableText6_1'),
              }
            ] ,
            USDList: [
              {
                USD: '1600',
                text: ''
              }
            ],
            question: true,
            rights: {
              defalut: [
                this.$t('sponsorTableText15_2'),
                this.$t('sponsorTableText15_3'),
              ],
              onLine: [],
              offLine: []
            },
            momeyRMB: '10000RMB, $1600',
            imgs: [
              'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/sponsorChance/15.png',
            ]
          },
          {
            id: 16,
            company: this.$t('sponsorTableText16_1'),
            amount: this.$t('exclusive'),
            RMBList: [
              {
                RMB: '20000',
                text: this.$t('sponsorTableText5_1'),
              }
            ] ,
            USDList: [
              {
                USD: '3300',
                text: ''
              }
            ],
            question: true,
            rights: {
              defalut: [
                this.$t('sponsorTableText16_2'),
                this.$t('sponsorTableText16_3'),
              ],
              onLine: [],
              offLine: []
            },
            momeyRMB: this.$t('sponsorTableText16_4'),
            imgs: [
              'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/sponsorChance/16.png',
            ]
          },
          {
            id: 17,
            company: this.$t('sponsorTableText17_1'),
            amount: this.$t('onlyFiveCompanies'),
            RMBList: [
              {
                RMB: '15000',
                text: ''
              }
            ] ,
            USDList: [
              {
                USD: '2200',
                text: ''
              }
            ],
            question: true,
            rights: {
              defalut: [
                this.$t('sponsorTableText17_2'),
                this.$t('sponsorTableText17_3'),
              ],
              onLine: [],
              offLine: []
            },
            momeyRMB: '15000RMB, $2200',
            imgs: [
              'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/sponsorChance/17.jpg',
            ]
          },
          {
            id: 18,
            way: this.$t('onlineSponsor'),
            company: this.$t('sponsorTableText18_1'),
            amount: this.$t('sponsorTableText18_2'),
            RMBList: [
              {
                RMB: '10000',
                text: ''
              }
            ] ,
            USDList: [
              {
                USD: '1,500 ',
                text: ''
              }
            ],
            question: true,
            rights: {
              defalut: [
                this.$t('sponsorTableText18_3'),
                this.$t('sponsorTableText18_4'),
              ],
              onLine: [],
              offLine: []
            },
            momeyRMB: this.$t('sponsorTableText18_5'),
            imgs: [
              'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/sponsorChance/18.jpg',
            ]
          },
          {
            id: 19,
            company: this.$t('sponsorTableText19_1'),
            amount: this.$t('sponsorTableText19_2'),
            RMBList: [
              {
                RMB: '10000',
                text: ''
              }
            ] ,
            USDList: [
              {
                USD: '1,500',
                text: ''
              }
            ],
            question: true,
            rights: {
              defalut: [
                this.$t('sponsorTableText19_3'),
                this.$t('sponsorTableText19_4'),
              ],
              onLine: [],
              offLine: []
            },
            momeyRMB: '10000RMB, $1500',
            imgs: [
              'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/sponsorChance/19.png',
            ]
          },
          {
            id: 20,
            company:  this.$t('sponsorTableText20_1'),
            amount: this.$t('sponsorTableText20_2'),
            RMBList: [
              {
                RMB: '15000',
                text: ''
              }
            ] ,
            USDList: [
              {
                USD: '2,250',
                text: ''
              }
            ],
            question: true,
            rights: {
              defalut: [
                this.$t('sponsorTableText20_3'),
                this.$t('sponsorTableText20_4'),
              ],
              onLine: [],
              offLine: []
            },
            momeyRMB: '15000RMB, $2250',
            imgs: [
             'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/sponsorChance/20.jpg',
            ]
          },
          {
            id: 21,
            company: this.$t('sponsorTableText21_1'),
            amount: this.$t('sponsorTableText21_2'),
            RMBList: [
              {
                RMB: '15000',
                text: ''
              }
            ] ,
            USDList: [
              {
                USD: '2,250',
                text: ''
              }
            ],
            question: false,
          },
          {
            id: 22,
            company: this.$t('sponsorTableText22_1'),
            amount: this.$t('sponsorTableText22_2'),
            RMBList: [
              {
                RMB: '10000',
                text: ''
              }
            ] ,
            USDList: [
              {
                USD: '1,500',
                text: ''
              }
            ],
            question: true,
            rights: {
              defalut: [
                this.$t('sponsorTableText22_3'),
                this.$t('sponsorTableText22_4'),
              ],
              onLine: [],
              offLine: []
            },
            momeyRMB: '10000RMB, $1500',
            imgs: [
              'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/sponsorChance/22.jpg',
            ]
          },
          {
            id: 23,
            company: this.$t('sponsorTableText23_1'),
            amount: this.$t('sponsorTableText23_2'),
            RMBList: [
              {
                RMB: '8000',
                text: ''
              }
            ] ,
            USDList: [
              {
                USD: '1,200',
                text: ''
              }
            ],
            question: true,
            rights: {
              defalut: [
                this.$t('sponsorTableText23_3'),
                this.$t('sponsorTableText23_4'),
              ],
              onLine: [],
              offLine: []
            },
            momeyRMB: '8000RMB, $1200',
            imgs: [
             'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/sponsorChance/23.png',
            ]
          },
          {
            id: 24,
            company: this.$t('sponsorTableText24_1'),
            amount: this.$t('sponsorTableText24_2'),
            RMBList: [
              {
                RMB: '15000',
                text: ''
              }
            ] ,
            USDList: [
              {
                USD: '2,250',
                text: ''
              }
            ],
            question: true,
            rights: {
              defalut: [
                this.$t('sponsorTableText24_3'),
              ],
              onLine: [],
              offLine: []
            },
            momeyRMB: '15000RMB, $2250',
            imgs: [
              'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/sponsorChance/24.png',
            ]
          },
          {
            id: 25,
            company: this.$t('sponsorTableText25_1'),
            amount: this.$t('sponsorTableText25_2'),
            RMBList: [
              {
                RMB: '15000',
                text: ''
              }
            ] ,
            USDList: [
              {
                USD: '2,250',
                text: ''
              }
            ],
            question: true,
            rights: {
              defalut: [
                this.$t('sponsorTableText25_3')
              ],
              onLine: [],
              offLine: []
            },
            momeyRMB: '15000RMB, $2250',
            imgs: [
              'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/sponsorChance/25.png',
            ]
          },
          {
            id: 26,
            company:  this.$t('Others'),
            amount:  this.$t('Others'),
            RMBList: [
              {
                RMB: '',
                text: '',
                end: '*'
              }
            ] ,
            USDList: [
              {
                USD: '',
                text: '',
                end: '*'
              }
            ],
            question: false
          },
        ],
        dialogVisible: false,
        detailRow: {
          id: 2,
          company: '',
          amount: '',
          RMBList: [
            {
              RMB: '',
              text: ''
            }
          ] ,
          USDList: [
            {
              USD: '',
              text: ''
            }
          ],
          question: true,
          rights: {
            defalut: [],
            onLine: [],
            offLine: []
          },
          momeyRMB: '',
          imgs: []
        }
      }
    },
    methods: {
      tableSpanMethod({ row, column, rowIndex, columnIndex }) {
        if (columnIndex === 0) {
          if ( rowIndex === 0){
            return {
              rowspan: 17,
              colspan: 1,
            };
          } else if (  rowIndex === 17 ){
            return {
              rowspan: 9,
              colspan: 1,
            }
          } else {
            return {
              rowspan: 1,
              colspan: 0,
            }
          }
        }
      },
      cellClassNameFn({row, column, rowIndex, columnIndex}) {
        if (rowIndex  === 0 && columnIndex  === 0) {
          return 'sponsorChanceBg2292FF'
        } else if (columnIndex === 0 && rowIndex === 17) {
          return 'sponsorChanceBgFD9D9D'
        }
      },
      handleQuestion(row) {
        this.detailRow = Object.assign({}, row)
        this.dialogVisible = true
      },
      beforeClose() {
        this.dialogVisible = false
      },
    }
  }
</script>

<style scoped lang="less">
.title {
  padding: 32px 0;
  text-align: center;
  font-size: 24px;
  font-weight: 800;
  color: #333333;
}
.table {
  padding-bottom: 70px;
}
.companyBox {
  display: flex;
  justify-content: center;
  align-items: center;
  .company {
    color: #1F292E;
    font-size: 18px;
    font-weight: 800;
  }
  .companyQuestion {
    font-size: 12px;
    color: #2292FF;
    margin-left: 4px;
    white-space:nowrap
  }
}
.amount {
  color: #1F292E;
  font-size: 18px;
}
.moneyList {
  display: flex;
  justify-content: space-around;
  align-items: center;
  .moneyItem {
    text-align: center;
    .unitIcon {
      color: #DD9740;
      font-size: 14px;
      font-weight: 800;
    }
    .moneyNumber {
      color: #DD9740;
      font-size: 18px;
      font-weight: 800;
    }
    .RMB {
      color: #DD9740;
      font-size: 12px;
      font-weight: 400;
    }
    .text {
      font-size: 14px;
      font-weight: 800;
      color: #1F292E;
    }
    .textActive {
      font-size: 14px;
      font-weight: 800;
      color: #909399;
    }
    .unit {
      color: #1F292E;
      font-size: 14px;
      font-weight: 800;
    }
  }
}
/deep/ .el-table th {
  padding: 10px;
  font-size: 16px;
  background: #F7F7F7;
  .cell {
    color: #1F292E;
    font-size: 18px;
    font-weight: 800;
  }
}
/deep/ .el-table thead tr th {
  border-right: none!important;
}
/deep/ .el-table tbody tr:hover>td {
  background-color:#ffffff
}
/deep/ .el-table tbody tr:nth-child(1):hover>td:nth-child(1) {
  background: #2292FF;
}
/deep/ .el-table tbody tr:nth-child(18):hover>td:nth-child(1) {
  background: #FD9D9D;
}
/deep/ .el-dialog__body {
  padding: 20px 0;
}
.rightsTitle {
  padding-top: 10px;
  font-size: 24px;
  padding-bottom: 10px;
  padding-left: 10px;
}
.rightsBox {
  width: 1000px;
  text-align: left;
  padding-left: 25px;
}
.momeyRMBTitle {
  margin-top: 20px;
  font-size: 16px;
  padding-left: 25px;
}
.imgs {
  max-width: 1300px;
  margin-top: 20px;
  text-align: center;
  .img {
    max-width: 1300px;
  }
}
.right {
  margin-top: 5px;
}
.color20{
  font-size: 20px;
  padding: 10px 0;
}
.english {
  word-break:keep-all;
}
</style>
<style lang="less">
.sponsorChanceBg2292FF {
    background: #2292FF;
    /deep/ .cell {
      font-size: 21px;
      color: #FFFFFF;
      font-weight: 800;
    }
  }
.sponsorChanceBgFD9D9D {
    background: #FD9D9D;
    /deep/ .cell {
      font-size: 21px;
      color: #FFFFFF;
      font-weight: 800;
    }
  }
</style>